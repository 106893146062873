import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import { LogPagingRequest, LogPagingResponse ,SystemLogFiltersInput} from 'src/app/core/modules/graphql/generated/graphql';
import { SystemService } from './system.service';



@Injectable()
export class LogResolver {

  private subject = new BehaviorSubject<LogPagingResponse>({});

  logPage$: Observable<LogPagingResponse> = this.subject.asObservable();


  constructor(private systemService: SystemService) {
  }

  loadFirstPage( pagingRequest: LogPagingRequest): Observable<any> {
    
    return this.loadPage( pagingRequest);
  }
  previous( pagingRequest: LogPagingRequest): Observable<LogPagingResponse> {

    return this.loadPage( pagingRequest);
  }

  next(pagingRequest: LogPagingRequest): Observable<LogPagingResponse> {
  
    return this.loadPage( pagingRequest);
  }

  loadPage(pagingRequest: LogPagingRequest = {
   
    offset: 0,
    limit: 10,
    sortColumn: [0],
    sortDir: [0],
   
  }): Observable<LogPagingResponse> {
    return this.systemService.getAllSystemLogs( pagingRequest)
      .pipe(tap(data => this.subject.next(data)));
  }

  // private loadPage(pagingRequest: LogPagingRequest): Observable<LogPagingResponse> {
  //   return this.systemService.getAllSystemLogs(pagingRequest)
  //     .pipe(tap(data => this.subject.next(data)));
  // }
  

  // loadPage(pagingRequest: LogPagingRequest = {
  //   offset: 0,
  //   limit: 10,
  //   sortColumn: [0],
  //   sortDir: [0],
  //     }): Observable<LogPagingResponse> {
  //   return this.systemService.getAllSystemLogs(pagingRequest)
  //     .pipe(tap(data => this.subject.next(data)));
  // }

  onDestroy() {
    this.subject.next({});
    this.subject.complete();
  }


}
