import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {UserPagingRequest, UserPagingResponse} from '../../../../../../../../core/modules/graphql/generated/graphql';
import {UsersManagementService} from '../../../services/user-management/users-management.service';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersResolver
{

  public subject = new BehaviorSubject<UserPagingResponse>({});

  usersPage$: Observable<UserPagingResponse> = this.subject.asObservable();

  constructor(private usersManagementService: UsersManagementService)
  {
  }

  loadFirstPage(roleCode: string, pagingRequest: UserPagingRequest, status: string,sportsids,objUser): Observable<any>
  {
    return this.loadPage(roleCode, pagingRequest, status,sportsids,objUser);
  }
  previous(roleCode: string, pagingRequest: UserPagingRequest, status: string,sportsids,objUser): Observable<UserPagingResponse>
  {

    return this.loadPage(roleCode, pagingRequest, status,sportsids,objUser);
  }

  next(roleCode: string, pagingRequest: UserPagingRequest, status: string,sportsids,objUser): Observable<UserPagingResponse>
  {
    return this.loadPage(roleCode, pagingRequest, status,sportsids,objUser);
  }

  public loadPage(roleCode: string = 'REGISTERED_USER', pagingRequest: UserPagingRequest, status: string,sportList,objUser:any): Observable<UserPagingResponse>
  {
    let obj={};
    localStorage.setItem('selectedUserRole', roleCode);
        sportList

    if(roleCode ==='new'){
      obj['roleCode'] = "REGISTERED_USER"
      obj['pagingRequest'] =pagingRequest
      obj['status'] = status.toUpperCase()
      obj['users'] = "USERS"
    }else{

    obj['roleCode'] = roleCode.toUpperCase(),
    obj['users'] = objUser.users,
    obj['registeredUser'] = objUser.registeredUser,
    obj['volunteer'] = objUser.volunteer,
    obj['pagingRequest'] = pagingRequest,
    obj['status']= status.toUpperCase(),
      obj['sportList'] = sportList


  }
    return this.usersManagementService.getListUserProfile({obj

      })
      .pipe(tap(users => this.subject.next(users)));
  }

  onDestroy()
  {
    this.subject.next({});
    this.subject.complete();
  }

}
