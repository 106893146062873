//TODO refactor change to use momentJS
export const momentParseTime = (time) =>
{
  if(time || time !== '') {
    const arrTime = time.split(/[\s:]+/);
    let hour = +arrTime[0];
    const minute = +arrTime[1];
    const a = arrTime[2];

    if (hour === 12){
      hour = 0;
    }

    if (a === 'pm') {
      hour = hour + 12;
    }

    return {hour: hour, minute: minute, second: 0};
  } else {
      return null;
  }
};
