import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LogPagingRequest, LogPagingResponse, QueryGetAllSystemLogsGQL,QueryGetFilterDataForSystemLogsGQL, 
  QueryGetdownloadSystemLogExcelReportGQL,SystemLogFiltersInput
} from 'src/app/core/modules/graphql/generated/graphql';
import * as _ from 'lodash';

 @Injectable({
  providedIn: 'root'
 })
export class SystemService {

  constructor(
    private queryGetAllSystemLogsGQL: QueryGetAllSystemLogsGQL,
    private queryGetFilterDataForSystemLogsGQL:QueryGetFilterDataForSystemLogsGQL,
    private queryGetdownloadSystemLogExcelReportGQL:QueryGetdownloadSystemLogExcelReportGQL
  ) { }

  getAllSystemLogs(pagingRequest: LogPagingRequest):Observable<LogPagingResponse> {
    // if(_.isEmpty(systemLogFilters.usernames)){
    //   delete systemLogFilters.usernames;
    // }
    // if(_.isEmpty(systemLogFilters.emails)){
    //   delete systemLogFilters.emails;
    // }
    // if(_.isEmpty(systemLogFilters.from)){
    //   delete systemLogFilters.from;
    // }
    // if(_.isEmpty(systemLogFilters.to)){
    //   delete systemLogFilters.to;
    // }
    return this.queryGetAllSystemLogsGQL.watch(
      {
      pagingRequest: pagingRequest
      }
    ).valueChanges.pipe(map(({data}) => data.getAllSystemLogs));
}

getFilterDataForSystemLogs(pagingRequest:LogPagingRequest,filterType?):Observable<any>{
  return this.queryGetFilterDataForSystemLogsGQL.watch(
    {
      pagingRequest:pagingRequest
    }
  ).valueChanges.pipe(map(({data})=>{
    if(!filterType || !data.getFilterDataForSystemLogs.data){
      return data.getFilterDataForSystemLogs
    }
    return {
      [filterType] : data.getFilterDataForSystemLogs.data[0][filterType],
      total: data.getFilterDataForSystemLogs.total
    }
  }
  ))
}

 downloadSystemLogExcelReport(pagingRequest: LogPagingRequest = {
  offset: 0,
  limit: 10,
  sortColumn: [0],
  sortDir: [0]
}){

   return this.queryGetdownloadSystemLogExcelReportGQL.watch(
     {
     pagingRequest:pagingRequest
     }
   ).valueChanges.pipe(map(({data})=> data.downloadSystemLogExcelReport))
 }

}
