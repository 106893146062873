import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {
	ArticleInput,
	MutationChangeStatusSportGQL,
	MutationCreateSportArticleGQL,
	MutationDeleteSportGQL,
	MutationUpdateSportArticleGQL,
	QueryGetListSportForAdminGQL,
	QueryGetSportForAdminGQL,
	Sport,
	SportPagingRequest
} from '../../../../../../core/modules/graphql/generated/graphql';
import {Observable} from 'rxjs';

@Injectable()
export class SportManagementService
{

	constructor(private mutationCreateSportArticleGQL: MutationCreateSportArticleGQL,
	            private mutationUpdateSportArticleGQL: MutationUpdateSportArticleGQL,
	            private mutationDeleteSportGQL: MutationDeleteSportGQL,
	            private mutationChangeStatusSportGQL: MutationChangeStatusSportGQL,
	            private queryGetListSportForAdminGQL: QueryGetListSportForAdminGQL,
	            private queryGetSportForAdminGQL: QueryGetSportForAdminGQL)
	{

	}

	getListSportForAdmin(classificationId: string ,filter:  SportPagingRequest): Observable<Sport[]>
	{
		return this.queryGetListSportForAdminGQL.watch(
			{
				classificationId,
                filter
			}
		).valueChanges.pipe(map(({data}) => data.getListSportForAdmin));
	}

	getSportForAdmin(id: string): Observable<Sport>
	{
		return this.queryGetSportForAdminGQL.watch(
			{
				id: id
			}
		).valueChanges.pipe(map(({data}) => data.getSportForAdmin));
	}

	createSportArticle(article: ArticleInput, classificationIds: string[],assessments: string[])
	{
		return this.mutationCreateSportArticleGQL
			.mutate({
				article: article,
				classificationIds: classificationIds,
        assessments: assessments
			})
			.pipe(map(({data}) => data.createSportArticle));
	}

	updateSportArticle(article: ArticleInput, sportId: string, classificationIds: string[],assessments: string[])
	{
		return this.mutationUpdateSportArticleGQL
			.mutate({
				article: article,
				sportId: sportId,
				classificationIds: classificationIds,
        assessments: assessments
			})
			.pipe(map(({data}) => data.updateSportArticle));
	}

	deleteSport(id: string)
	{
		return this.mutationDeleteSportGQL
			.mutate({
				id: id
			})
			.pipe(map(({data}) => data.deleteSport));
	}

	changeStatusSport(id: string, status: string)
	{
		return this.mutationChangeStatusSportGQL
			.mutate({
					id: id,
					status: status
				},
				{
					refetchQueries:
						[
							{
								query: this.queryGetSportForAdminGQL.document,
								variables: {
									id: id
								}
							}
						]
				})
			.pipe(map(({data}) => data.changeStatusSport));
	}
}
