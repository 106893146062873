import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DateInput, EventInput} from '../../../../../../../../../core/modules/graphql/generated/graphql';
import {momentParseDate, momentParseDateTime} from '../../../../../../../../../core/utils/momentParseDate';
import * as moment from 'moment';
import { momentParseTime } from 'src/app/core/utils/momentParseTime';

export const initForm = (formBuilder: FormBuilder) =>
{

  return formBuilder.group({
    eventName: [{value: null, disabled: false}, Validators.required],
    //  date: [{value: null, disabled: false}, Validators.required],
    fromTime: [{value: null, disabled: false}, Validators.required],
    toTime: [{value: null, disabled: false}, Validators.required],
    status: [{value: 'Open', disabled: false}],
    eventType: [{value: null, disabled: false}, Validators.required],
    sportIds: [{value: null, disabled: false}, Validators.required],
    sports: [{value: null, disabled: false}],
    eventRoles: [{value: null, disabled: false}, Validators.required],
    totalVacancy: [{value: null, disabled: false}, [Validators.required, Validators.min(1)]],
    venue: [{value: null, disabled: false}],
    register: [{value: null, disabled: false}],
    description: [{value: null, disabled: false}],
    available: [{value: null, disabled: false}],
    organiser: [{value: null, disabled: false}],
    additionalInfoList: formBuilder.array([]),
    cancelReason: [{value: '', disabled: false}],
    externalLink: [{value: '', disabled: false}],
     dateRange1: [{value: null, disabled: false}, Validators.required],
     dateRange2: [{value: null, disabled: false}, Validators.required],
     emailNotification:[{value: true, disabled: false}],



  });
};

export const eventInformation = (eventId: string, formValue): EventInput =>
{

  const parseFromTime = formValue.fromTime
    ? momentParseTime(formValue.fromTime)
    : null;

  const parseToTime = formValue.toTime
    ? momentParseTime(formValue.toTime)
    : null;


  return {

    id: eventId,
    eventName: formValue.eventName,
    //  eventDateObj: formValue.date ? momentParseDate(formValue.date) : null,
     toEventDateObj : formValue.dateRange2.end ? momentParseDate(moment(formValue.dateRange2.end)) : null,
      fromEventDateObj:formValue.dateRange2.begin ? momentParseDate(moment(formValue.dateRange2.begin)) : null,
     toRegDate: formValue.dateRange1.end ? momentParseDate(moment(formValue.dateRange1.end)) : null,
     fromRegDate: formValue.dateRange1.begin ? momentParseDate(moment(formValue.dateRange1.begin)) : null,
    fromTimeObj: parseFromTime,
    toTimeObj: parseToTime,
    eventType: formValue.eventType,
     eventRoles: formValue.eventRoles,
    status: formValue.status,
    sportIds: formValue.sportIds,
    description: formValue.description,
    venue: formValue.venue,
    totalVacancy: formValue.totalVacancy,
    organiser: formValue.organiser,
    additionalInfoList: formValue.additionalInfoList,
    cancelReason: formValue.cancelReason,
    externalLink: formValue.externalLink,
    emailNotification: formValue.emailNotification
  };
};
export const dateInformation = (eventId: string, formValue): DateInput =>
{



  return {

    dateObj: formValue.dateObj ? momentParseDate(formValue.dateObj) : null,
    advStartDateObj: formValue.advStartDateObj ? momentParseDate(formValue.advStartDateObj) : null,
    advEndDateObj: formValue.advEndDateObj ? momentParseDate(formValue.advEndDateObj) : null,


  };
};
export const dateInformationPost = (eventId: string, formValue): DateInput =>
{



  return {

    dateObj: formValue.dateObj ? momentParseDateTime(formValue.dateObj) : null,
    advStartDateObj: formValue.advStartDateObj ? momentParseDateTime(formValue.advStartDateObj) : null,
    advEndDateObj: formValue.advEndDateObj ? momentParseDateTime(formValue.advEndDateObj) : null,


  };
};
export const dateInformationSys = (formValue): DateInput =>
{



  return {

    dateObj: formValue.dateObj ? momentParseDate(formValue) : null,


  };
};
